<template>
  <v-container>
    <h1>Organization Settings</h1>
    <v-row class="justify-lg-space-between">
      <v-col cols="12" md="4">
        <h3>Organization Information</h3>
        <p>Update your organization's information</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text>
            <v-form>
              <v-text-field v-model="name" label="Name" filled />
              <v-text-field :value="type" label="type" filled disabled />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="saveOrganizationInformation">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import event, { Events } from '../../event'

export default {
  name: 'OrganizationSettings',
  data () {
    return {
      name: this.$store.state.Organization.name,
      type: this.$store.state.Organization.type
    }
  },
  methods: {
    async saveOrganizationInformation () {
      await window.axios.post(this.$store.getters['Organization/apiUrl'], {
        name: this.name
      })

      this.$store.commit('Organization/setOrganizationName', this.name)

      // emit the success event
      event.emit(Events.SUCCESS, 'Profile updated successfully')
    }
  }
}
</script>
